import React from "react"
import ReviewImage from "./ReviewImage"
import { RichTextElement } from "@kentico/gatsby-kontent-components"
import { shuffle } from "../../assets/js/helper"
import { graphql, useStaticQuery } from "gatsby"
import GoogleRating from "../google-rating-banner/GoogleRating"
import Quote from '../../assets/images/quote-left.png'

const StaticReview = ({ staticReviews, reviewImages }) => {
  const data = useStaticQuery(getGreb)
  const { allKontentItemSettings } = data
  const { google_rating_banner } = allKontentItemSettings.nodes[0].elements

  const googleRatingBanner = () => {
    if (google_rating_banner.value[0]) {
      return (
        <section>
          <GoogleRating
            url={google_rating_banner.value[0].elements.google_url.value}
          />
        </section>
      )
    }
  }

  const getRating = rating => {
    switch (rating.value[0].codename) {
      case "n1_star":
        return "★"
      case "n2_stars":
        return "★★"
      case "n3_stars":
        return "★★★"
      case "n4_stars":
        return "★★★★"
      case "n5_stars":
        return "★★★★★"
      default:
        break
    }
  }

  const { rating, review, reviewer } = shuffle(staticReviews.value).elements

  return (
    <>
      {googleRatingBanner()}
      <div className="singleReview_container container">
        <div className="singleReview_info">
        <div className='divider'>
              <div className='quote'>
                <img src={Quote} alt='quote'/>
              </div>
            </div>
          <div className="review_quote">
            <RichTextElement value={review.value} />
          </div>
          <div className="static-review-details">
            <div>{reviewer.value}</div>
          </div>
          <div>
            <div className="review_stars">{getRating(rating)}</div>
          </div>
        </div>
        <ReviewImage image={shuffle(reviewImages.value)} />
      </div>
    </>
  )
}

const getGreb = graphql`
  {
    allKontentItemSettings {
      nodes {
        elements {
          google_rating_banner {
            value {
              ... on kontent_item_google_rating_banner {
                id
                elements {
                  google_url {
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default StaticReview
