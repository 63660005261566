import React from "react"
import GlobalFooterItem from "./GlobalFooterItem"
import SingleReview from "../reviews/SingleReview"
import StaticReview from "../reviews/StaticReview"
const GlobalFooter = ({
  content,
  phone,
  pestroutesReviews,
  staticReviews,
  reviewImages,
  meta,
  pestroutesLogo,
}) => {
  const { global_footer_items } = content.value[0].elements

  const getSingleReview = id => {
    return pestroutesReviews.value[0].codename === "yes" ? (
      <SingleReview key={id} reviewImages={reviewImages} />
    ) : (
      <StaticReview
        key={id}
        reviewImages={reviewImages}
        staticReviews={staticReviews}
      />
    )
  }
  return (
    <section>
      {global_footer_items.value.map(({ id, elements, system }) => {
        if (system && system.type === "review_position") {
          return getSingleReview(id)
        } else {
          return (
            <GlobalFooterItem
              key={id}
              blocks={elements}
              phone={phone}
              meta={meta}
            />
          )
        }
      })}
    </section>
  )
}

export default GlobalFooter
